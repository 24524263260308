// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  padding: 15px 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.273);
}
`, "",{"version":3,"sources":["webpack://./src/admin/css/footer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,0CAA0C;AAC5C","sourcesContent":[".footer {\r\n  padding: 15px 30px;\r\n  border-top: 1px solid rgba(0, 0, 0, 0.273);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
