// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-loading{
    background-color: rgba(0, 0, 0, 0.548);
}`, "",{"version":3,"sources":["webpack://./src/site/css/auth.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;AAC1C","sourcesContent":[".wrapper-loading{\r\n    background-color: rgba(0, 0, 0, 0.548);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
