// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card__sales{
    transition: 0.3s ease;
}

.card__sales:hover{
    background: rgba(219, 217, 217, 0.678) !important;
}
`, "",{"version":3,"sources":["webpack://./src/admin/css/sales/salesManagement.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,iDAAiD;AACrD","sourcesContent":[".card__sales{\r\n    transition: 0.3s ease;\r\n}\r\n\r\n.card__sales:hover{\r\n    background: rgba(219, 217, 217, 0.678) !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
